import axios from 'axios';
import store from '@/store';

import { BASE_URL} from '../../config';
import { getOpenId } from '../utils/auth'
import { Notify, Toast } from 'vant'
import showAuthDialog from '../utils/show-auth-dialog';

const TIMEOUT =  5 * 60 * 1000; // 5 分钟
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL, //  "/api/",
  timeout: TIMEOUT
});

// 状态码错误信息
const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

// request 拦截器
service.interceptors.request.use(
  config => {
    config.headers['zcyy'] = 1;
    config.headers['platform'] = 'wechat'; // app , wechat, weapp
    if (store.getters.token) {
      config.headers['loginToken'] = store.getters.token
    }
    if (getOpenId()) {
      config.headers['openId'] = getOpenId();
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)
// respone 拦截器
service.interceptors.response.use(
  response => {
    const data = response.data.wrap ? response.data.content : response.data;
    const status = data.status;
    const code = data.code ? data.code : response.data.code;
    
    let message = data.message ? data.message : response.data.message;

    const isSuccess = status ? status == 200 : (code == 2000 || code == 200 || code == 9999);
    if ( isSuccess ) {
      return data;
    } else {
      //  失败状态码 服务器在处理的时候发生错误
      switch (code) {
        case 4000:
        case 4001:
        case 4002:
        case 4003:
          // token 失效等
          store.dispatch('resetToken').then( () => {
            showAuthDialog();
          });
          message = "未认证的访问!";
          break;
        case 5000:
        case 5001:
          message = "系统错误，请联系管理员！";
          break;
        default:
          break;
      }
      if (code && ( code != 200 && code != 2000 )) {
        if (message.indexOf("详细信息请看data") > -1) {
          message += JSON.stringify(data.data);
        }
        return Promise.reject(new Error(message));
      }
      return data;
    }
  },
  error => {
    if (axios.isCancel(error)) {
      return Promise.reject(new Error('cancel'));
    }
    let status = '',
        errorText = '';
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      status = error.response.status;
      errorText = codeMessage[status] || error.response.statusText;

      if ( error.response.data !== undefined) {
        if (error.response.data.status === 40301) {
          store.dispatch('resetToken').then( () => {
            showAuthDialog();
          });
          errorText = '未认证的访问'
          Toast({message: errorText, duration: 3000})
          return Promise.reject({code: status, message: errorText});
        }
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
      status = error.code;
      if (error.code == 'ECONNABORTED') {
        errorText = '请求超时';
      } else {
        errorText = '网络异常';
      }
    } else {
      // Something happened in setting up the request that triggered an Error
      status = error.code;
      errorText = error.message;
    }
    Notify({message: `(${status}) ${errorText}`, type: 'danger'});
    console.log(`[${error.config.method}](${error.config.url})请求失败，响应数据：%o， 错误码： %o`, error.response, error.code);
    return Promise.reject({code: status, message: errorText});
  }
);

export default service;
